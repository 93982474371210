import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import { registerControllers as registerLayoutControllers } from "@asgerb/apparatus-layout"

const application = Application.start()
const controllers = import.meta.globEager("./**/*_controller.js")
const componentControllers = import.meta.globEager(
  "../../components/**/*_controller.js"
)

registerControllers(application, controllers)
registerControllers(application, componentControllers)
registerLayoutControllers(application)
