import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { open: { type: Boolean, default: false }, rootPath: String }

  connect() {
    document.body.classList.toggle("overlay_open", this.openValue)
    this.element.scrollTop = 0
  }

  onLoad() {
    this.element.scrollTop = 0
    this._toggleBodyClass()
  }

  _toggleBodyClass() {
    document.body.classList.toggle(
      "overlay_open",
      !this.element.matches(":empty")
    )
  }

  _pushHistory(url) {
    Turbo.navigator.history.push(url)
  }
}
